<template>
    <div>
        <div class="setting_content">
            <div class="setting_title" style="border-bottom: 1px solid #EFEFEF;">
                鱼河农场马铃薯大屏设置
            </div>
            <div class="setting_content_content">
                <!-- 基本信息 -->
                <BasicInformation></BasicInformation>
                <div class="line"></div>
                <YearsTableLsit></YearsTableLsit>
                <div class="line"></div>
                <cameraList></cameraList>
                <div class="line"></div>
                <iotMeteorologicalList></iotMeteorologicalList>
                <div class="line"></div>
                <iotSoilList></iotSoilList>
                <!-- <div class="line"></div>
                <PersonnelTableLsit></PersonnelTableLsit>
                <div class="line"></div>
                <AgriculturalMachineryTableLsit></AgriculturalMachineryTableLsit>
                <div class="line"></div>
                <PlotTableLsit></PlotTableLsit> -->
                 <div class="line"></div>
<!--                <TaskprogressTableLsit></TaskprogressTableLsit>-->
<!--                <div class="line"></div>-->
                <WarningTableLsit></WarningTableLsit>
            </div>
        </div>
    </div>
</template>

<script>
import BasicInformation from './components/dataScreen4_potato_YH/basicInformation.vue'
import YearsTableLsit from './components/dataScreen4_potato_YH/yearsTableLsit.vue'
import IotMeteorologicalList from './components/dataScreen4_potato_YH/iotMeteorologicalList.vue'

import IotSoilList from './components/dataScreen4_potato_YH/iotSoilList.vue'

import CameraList from './components/dataScreen4_potato_YH/cameraList.vue'

// import PersonnelTableLsit from './components/dataScreen6_potato_MJ/personnelTableLsit.vue'
// import AgriculturalMachineryTableLsit from './components/dataScreen6_potato_MJ/agriculturalMachineryTableLsit.vue'
// import PlotTableLsit from './components/dataScreen6_potato_MJ/plotTableLsit.vue'
import TaskprogressTableLsit from './components/dataScreen4_potato_YH/taskprogressTableLsit.vue'
import WarningTableLsit from './components/dataScreen4_potato_YH/warningTableLsit.vue'
export default {
    name: '',
    data(){
        return{
        }
    },
    components: {
        BasicInformation,
        YearsTableLsit,
        IotMeteorologicalList,
        IotSoilList,
        CameraList,
        // PersonnelTableLsit,
        // AgriculturalMachineryTableLsit,
        // PlotTableLsit,
        TaskprogressTableLsit,
        WarningTableLsit
    },
    mounted(){

    },
    methods:{
        
    }
}
</script>

<style scoped>
.menu_title {
    width: 100%;
    height: 146px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .setting_content {
      /* padding: 24px; */
      background: #fff;
  }
  .setting_title {
      padding: 24px;
      padding-bottom: 6px;
  }
  .setting_content_content {
      padding: 24px;
  }
  .bootom {
      width: calc(100% - 254px);
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 230px;
      background: #fff;
      margin-left: -24px;
      text-align: center;
      line-height: 44px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .disease-box {
    position: relative;
    
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px;
  }
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 50;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }

  .avatar-uploader {
    width: 150px;
    height: 162px;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 150px;
    height: 162px;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }
</style>