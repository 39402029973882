<template>
  <div>
    <el-form :inline="true" label-width="190px" :model="form" class="demo-form-inline">
      <div class="sub-title">基础信息</div>
      <el-row>
        <el-col>
          <el-form-item label="企业名称">
            <el-input v-model="form.screenName"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.yhMu"></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="form.yhAddr"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.totalPlantingAreaUnit"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.totalPlantingArea"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.totalPlantingAreaDesc"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.totalAnnualOutputUnit"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.totalAnnualOutput"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.totalAnnualOutputDesc"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.mapEnterpriseCountUnit"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.mapEnterpriseCount"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.mapEnterpriseCountDesc"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="sub-title">基地大数据</div>
      <el-row>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.jidiBigdataTotalPlantingArea"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.jidiBigdataTotalPlantingAreaDesc"></el-input>
          </el-form-item>
          <el-form-item label="单位">
            <el-input v-model="form.jidiBigdataTotalPlantingAreaUnit"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.jidiBigdataMassifCount"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.jidiBigdataMassifCountDesc"></el-input>
          </el-form-item>
          <el-form-item label="单位">
            <el-input v-model="form.jidiBigdataMassifCountUnit"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.jidiBigdataPlantingVarietiesCount"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.jidiBigdataPlantingVarietiesCountDesc"></el-input>
          </el-form-item>
          <el-form-item label="单位">
            <el-input v-model="form.jidiBigdataPlantingVarietiesCountUnit"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.jidiBigdataArtisanCount"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.jidiBigdataArtisanCountDesc"></el-input>
          </el-form-item>
          <el-form-item label="单位">
            <el-input v-model="form.jidiBigdataArtisanCountUnit"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.jidiBigdataManagerCount"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.jidiBigdataManagerCountDesc"></el-input>
          </el-form-item>
          <el-form-item label="单位">
            <el-input v-model="form.jidiBigdataManagerCountUnit"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="标题">
            <el-input v-model="form.jidiBigdataMachineryCount"></el-input>
          </el-form-item>
          <el-form-item label="数值">
            <el-input v-model="form.jidiBigdataMachineryCountDesc"></el-input>
          </el-form-item>
          <el-form-item label="单位">
            <el-input v-model="form.jidiBigdataMachineryCountUnit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div class="sub-title">基地活动资讯</div>
        <el-row>
          <el-col>
            <el-form-item label="标题">
              <el-input v-model="form.consult1"></el-input>
            </el-form-item>
            <el-form-item label="图片链接">
              <el-upload
                  class="avatar-uploader"
                  action="auth-web/uploadFileOSS"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess1"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.consult1Url" :src="form.consult1Url" class="avatar">
                  <div v-else class="ta_c">
                      <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                      <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                      <div class="ta_c hint"> RGB模式，不超过10M</div>
                  </div>
              </el-upload>
              <!--<el-input v-model="form.consult1Url"></el-input>-->
            </el-form-item>
            <el-form-item label="日期">
              <el-input v-model="form.consult1Time"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="标题">
              <el-input v-model="form.consult2"></el-input>
            </el-form-item>
            <el-form-item label="图片链接">
              <el-upload
                  class="avatar-uploader"
                  action="auth-web/uploadFileOSS"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.consult2Url" :src="form.consult2Url" class="avatar">
                  <div v-else class="ta_c">
                      <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                      <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                      <div class="ta_c hint"> RGB模式，不超过10M</div>
                  </div>
              </el-upload>
              <!--<el-input v-model="form.consult2Url"></el-input>-->
            </el-form-item>
            <el-form-item label="日期">
              <el-input v-model="form.consult2Time"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="sub-title">水肥情况</div>
        <el-row>
          <el-col>
            <el-form-item label="用水量统计   亩均">
              <el-input v-model="form.waterStatisticsAvgMu"></el-input>
            </el-form-item>
            <el-form-item label="总量">
              <el-input v-model="form.waterStatisticsTotalVal"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="用肥量统计   亩均">
              <el-input v-model="form.fertilizerStatisticsAvgMu"></el-input>
            </el-form-item>
            <el-form-item label="总量">
              <el-input v-model="form.fertilizerStatisticsTotalVal"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="sub-title">种植品种面积</div>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="名称">
            <el-input v-model="form.areaPlantedVarietiesAttr1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数量">
            <el-input v-model="form.areaPlantedVarietiesValue1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位">
            <el-input v-model="form.areaPlantedVarietiesUnit1"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="名称">
            <el-input v-model="form.areaPlantedVarietiesAttr2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数量">
            <el-input v-model="form.areaPlantedVarietiesValue2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位">
            <el-input v-model="form.areaPlantedVarietiesUnit2"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="名称">
            <el-input v-model="form.areaPlantedVarietiesAttr3"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数量">
            <el-input v-model="form.areaPlantedVarietiesValue3"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位">
            <el-input v-model="form.areaPlantedVarietiesUnit3"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="名称">
            <el-input v-model="form.areaPlantedVarietiesAttr4"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数量">
            <el-input v-model="form.areaPlantedVarietiesValue4"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位">
            <el-input v-model="form.areaPlantedVarietiesUnit4"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="地图地址">
            <el-upload
                class="avatar-uploader"
                action="auth-web/uploadFileOSS"
                accept=".mp4"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUploadMap">
              <video v-if="form.vedio3dUrl" :src="form.vedio3dUrl" class="avatar"></video>
              <div v-else class="ta_c">
                <i style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                <!-- <div class="ta_c hint mt10 mb5">支持jpg/png格式 </div> -->
                <div class="ta_c hint">仅支持mp4格式上传</div>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <div class="sub-title">基地实时画面</div>
      </el-row>
      <el-row>
        <el-form-item label="实时画面名称1">
          <el-input v-model="form.jidiBigVedio1Title"></el-input>
        </el-form-item>
        <el-form-item label="实时画面地址1">
          <el-input style="width: 550px" v-model="form.jidiBigVedio1Url"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="实时画面名称2">
          <el-input v-model="form.jidiBigVedio2Title"></el-input>
        </el-form-item>
        <el-form-item label="实时画面地址2">
          <el-input style="width: 550px" v-model="form.jidiBigVedio2Url"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="实时画面名称3">
          <el-input v-model="form.jidiBigVedio3Title"></el-input>
        </el-form-item>
        <el-form-item label="实时画面地址3">
          <el-input style="width: 550px" v-model="form.jidiBigVedio3Url"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="实时画面名称4">
          <el-input v-model="form.jidiBigVedio4Title"></el-input>
        </el-form-item>
        <el-form-item label="实时画面地址4">
          <el-input style="width: 550px" v-model="form.jidiBigVedio4Url"></el-input>
        </el-form-item>
      </el-row> -->
    </el-form>
    <el-row class="ta_c">
      <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
    </el-row>

  </div>
</template>

<script>

export default {
  name: '',
  data() {
    return {
      form: {
        mp4: ''
      }
    }
  },

  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.qa.datascreen4LoadDataBO({}).then(res => {
        if (res.respCode == '0000') {
          this.form = res.data;
        }
      })
    },
    handleAvatarSuccess1(res, file) {
      console.log(res)
      this.form.consult1Url = res.data.url
    },
    handleAvatarSuccess2(res, file) {
      console.log(res)
      this.form.consult2Url = res.data.url
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 10;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return  isLt2M;
    },
    onSaveInfo() {
      this.qa.datascreen4DoSave(this.form).then(res => {
        if (res.respCode == '0000') {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.getInfo()
        }
      })

    },
    beforeAvatarUploadMap(file) {
      const isJPG = file.type === 'video/mp4';
      //const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error('上传视频只能是 mp4 格式!');
      }
      // if (!isLt2M) {
      //     this.$message.error('上传头像图片大小不能超过 10MB!');
      // }
      // return  isLt2M;
    },
    handleAvatarSuccess(res, file) {
      this.form.vedio3dUrl = res.data.url
    },
  }
}
</script>

<style scoped>
.sub-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}

.avatar-uploader {
  width: 150px;
  height: 162px;
  border: 1px dashed #ccc;
  text-align: center;
}

.avatar-uploader .avatar {
  width: 150px;
  height: 162px;
}
</style>